
.btn-style {
    background: #6f00ff;
    border-radius: 23.621px;
    box-shadow: 0 0 3.779px 0 rgba(168, 27, 27, 0.25);
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 9px 18px;
}