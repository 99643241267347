.Home-style {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust this to fit your design */
    overflow: hidden;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Ensure video is behind the content */
  }

    .main-background-image {
      position:absolute;
      top: 0;
      left: 0;
      border-top: 1000px;
      width: 100%;
      height: 100%;
      /* object-fit: cover; */
      z-index: -1;
      /* Ensure video is behind the content */
    }
  
  .content-overlay {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
   
  }
  
  h1, p {
    color: #000; /* Ensure text is readable over the video */
  }