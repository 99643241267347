.team-container {
    padding: 20px;
}

.team-title {
    color: #6f00ff;
}

.team-description {
    color: #555;
}

.team-member-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.team-member {
    text-align: center;
}

.team-member-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.carousel-inner {
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.carousel-item {
    /* display: flex;
    justify-content: center; */
}

.carousel-control-prev,
.carousel-control-next {
    /* background-color: rgba(0, 0, 0, 0.5); */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: #6f00ff;
}