.extra-effect{
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    color:inherit
}
.extra-effect:hover{
    background-color: #6f00ff;
    transform: scale(1.05);
    color:#ffffff;
}
.extra-effect :hover p {
    background-color: #6f00ff;
    transform: scale(1.05);
    color:#ffffff;
}


.card p {
    font-size: 14px;
    color: #333;
    margin: 0 auto;
    line-height: 1.5;
}

.card h3 {
    font-weight: bold;
    color: #6f00ff;
}

.card-body {
    padding: 20px;
}